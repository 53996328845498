import { lectureItemWidth } from '~/constants/styles';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  outerBox: {
    overflowX: 'visible',
    width: '100%'
  },
  scroller: {
    overflowX: 'hidden',
    overflowY: 'visible',
    width: '100%'
    /* '&::-webkit-scrollbar': {
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.secondary.main,
            borderRadius: '2px'
        } */
  },
  root: {
    overflowX: 'visible',
    gridTemplateColumns: `repeat(4,${lectureItemWidth.lg + 7.5}px)`,
    justifyContent: 'space-between',
    width: '100%',
    minWidth: (props) => props.desktopMinWidth,
    marginBottom: 10,
    '@media (max-width: 1790px)': {
      gridTemplateColumns: `repeat(4,${lectureItemWidth.md + 7.5}px)`
    },
    '@media (max-width: 1400px)': {
      gridTemplateColumns: `repeat(3,${lectureItemWidth.md + 7.5}px)`
    },
    '@media (max-width: 450px)': {
      minWidth: (props) => props.mobileMinWidth,
      gridTemplateColumns: `repeat(3,${lectureItemWidth.sm + 7.5}px)`
    }
  },
  hide: {
    '@media (max-width: 1400px)': {
      display: 'none'
    }
  }
});
