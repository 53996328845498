/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import SpaceForm from '~/components/organisms/SpaceForm';
import SpaceReports from '~/components/organisms/SpaceReports';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import spaceServices from '~/services/Space';
import userSpaceAssociationServices from '~/services/user-space-association';
import { handleErrors, handleSuccess } from '~/utils/auxiliaryFunctions';
import { spaceValidations } from '~/utils/SpaceValidation/yup';

import { getSpace, updateSpace, deleteSpace } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function EditSpace() {
  const theme = useTheme();
  const { spaceID, environmentID, courseID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [reportURLs, setReportURLs] = useState('');
  const [space, setSpace] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const [defaultValues, setDefaultValues] = useState({
    id: null,
    name: '',
    initials: '',
    turn: '',
    description: '',
    dependent_subjects: false,
    order_subjects_by: 'created_at',
    keywords: [],
    mural_access: '000',
    avatar: null,
    avatarFileName: ''
  });

  const navOptions = [
    i18n.t('Information'),
    i18n.t('Reports'),
    i18n.t('Members')
  ];
  const successMessage = `A ${i18n.t(
    'StructuresName.Space.SingularLower'
  )} foi atualizada!`;
  const callbackSuccessPath = `/dashboard/spaces/${spaceID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    {
      name:
        location?.state?.environmentName ||
        i18n.t('StructuresName.Environment.SingularUpper'),
      path: `/dashboard/environments/${environmentID}`
    }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const handleDeleteConfirm = () => {
    deleteSpace(spaceID)
      .then(() => {
        enqueueSnackbar(
          `${i18n.t(
            'StructuresName.Space.SingularUpper'
          )} excluída com sucesso!`,
          { variant: 'success' }
        );

        history.push(
          `/dashboard/environments/${environmentID}/courses/${courseID}`
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `Erro ao excluir ${i18n.t('StructuresName.Space.SingularLower')}.`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const deleteMessage = () => {
    if (space && space.mirrors_count) {
      return (
        <>
          A {i18n.t('StructuresName.Space.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name} </span>
          possui {space.mirrors_count} espelho(s) e será permanentemente
          excluída, incluindo todos os dados e arquivos dos seus espelhos. Todos
          os membros irão perder o acesso.
        </>
      );
    } else {
      return (
        <>
          A {i18n.t('StructuresName.Space.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, será
          permanentemente excluída, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
    }
  };

  const renderContent = (props) => {
    const { selectedTab } = props;
    if (selectedTab === 0) {
      return (
        <GenericFormWrapper
          Form={SpaceForm}
          resolver={spaceValidations}
          defaultValues={defaultValues}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          isSubmitting={isSubmitting}
          {...props}
        />
      );
    } else if (selectedTab === 1) {
      return <SpaceReports space={space} />;
    } else if (selectedTab === 2) {
      return (
        <MembersManagement
          structureID={spaceID}
          structureServices={spaceServices}
          userStructureAssociationServices={userSpaceAssociationServices}
          reportURLs={reportURLs}
        />
      );
    }

    return false;
  };

  const configSpace = (response) => {
    const { data } = response;

    setReportURLs(data.report_urls);

    setDefaultValues({
      id: data.id,
      name: data.name,
      initials: data.initials,
      turn: data.turn,
      description: data.description,
      dependent_subjects: data.dependent_subjects,
      order_subjects_by: data.order_subjects_by,
      keywords: data.tags,
      mural_access: data.mural_access,
      avatarFileName: data.thumbnails ? data.thumbnails[0].filename : ''
    });
    setSpace(data);
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar a ${i18n.t(
        'StructuresName.Space.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (spaceID)
      getSpace(spaceID)
        .then(configSpace)
        .catch(fetchFailure);
  }, [spaceID]);
  const handleCancel = () => {
    history.push(`/dashboard/spaces/${spaceID}`);
  };

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    var formattedTagList = fields.keywords
      .map((keyword) => keyword.name)
      .join(',');
    delete fields.keywords;

    var body = { space: { ...fields, tag_list: formattedTagList } };

    updateSpace(spaceID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure)
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    document.title = `${theme.title} | Editar ${i18n.t(
      'StructuresName.Space.SingularLower'
    )}`;
  }, []);

  return (
    <>
      <StepperForm
        navOptions={navOptions}
        unclickable={false}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditSpace.title')}
        submitButtonTitle={i18n.t('EditSpace.saveButton')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteSpace.title')}
      />
    </>
  );
}
