/* eslint-disable react/prop-types */
import React from 'react';

import { SettingsIcon } from '~/components/atoms/Icons';

import {
  ListItemText,
  MenuItem,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function PopUpMenuItem({ icon: Icon, text, action }) {
  const theme = useTheme();
  const Icone = () => {
    if (!Icon) {
      return <div style={{ width: 20 }}></div>;
    }
    return <Icon style={{ fontSize: 20, color: theme.palette.primary.main }} />;
  };

  return (
    <>
      <MenuItem onClick={action}>
        <Icone />
        <ListItemText style={{ marginLeft: 10 }}>
          <Typography style={{ fontSize: 14 }} color="primary">
            {text}
          </Typography>
        </ListItemText>
      </MenuItem>
    </>
  );
}

PopUpMenuItem.propTypes = {
  text: PropTypes.string,
  showText: PropTypes.bool,
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
      Icon: PropTypes.any,
      disabled: PropTypes.bool
    })
  ),
  justOneLink: PropTypes.bool,
  Icon: PropTypes.any,
  onDelete: PropTypes.shape(),
  isCourse: PropTypes.bool
};

PopUpMenuItem.defaultProps = {
  text: '',
  showText: false,
  linkList: [],
  disabled: false,
  justOneLink: false,
  Icon: SettingsIcon,
  onDelete: null,
  isCourse: false
};
