// Import the API module to make HTTP requests
import api from '~/services/api';
import { buildUrlWithParams } from '~/utils/buildUrlWithParams';

// Define the base URL for the subjects API
const baseURL = '/api/subjects';

// Function to find a subject by its ID
export const findByID = async (subjectID) => {
  // Make a GET request to retrieve subject data by its ID
  const { data } = await api.get(baseURL + '/' + subjectID);

  // Return the response data from the API call
  return data;
};

// Function to fetch lectures for a specific subject by its ID and page number
export const getLectures = async (subjectID, page, perPage, filters = {}) => {
  // Make a GET request to fetch lectures for the specified subject and page
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${subjectID}/lectures`, {
      page,
      per_page: perPage,
      ...filters
    })
  );

  // Return the fetched lecture data
  return data;
};

export const getAllLectures = async (subjectID) => {
  // Make a GET request to fetch lectures for the specified subject and page
  const maxLecturesCount = 2 ** 32 - 1;
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${subjectID}/lectures`, {
      per_page: maxLecturesCount
    })
  );

  // Return the fetched lecture data
  return data;
};

// Function to remove a subject by its ID
export const remove = async (ID) => {
  // Make a DELETE request to remove the subject with the given ID
  const { data } = await api.delete(baseURL + `/${ID}`);

  // Return the response data from the API call
  return data;
};

// Export the 'findByID,' 'getLectures,' and 'remove' functions as part of an object containing all functions
export default {
  findByID,
  getLectures,
  remove
};
