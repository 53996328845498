import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: '56px', // Compensa ao ausência de css especifico do Componente AppbarPage para o correto posicionamento do conteúdo abaixo da appbar
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100vw',
    maxWidth: '100vw',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  nextLecturesPaper: {
    width: '324px',
    minWidth: '324px',
    maxWidth: '324px',
    padding: '16px',
    overflow: 'scroll',
    display: 'none',
    borderRight: '1px solid rgba(112, 112, 112, 0.2)',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B4BDD3 !important'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#A8B1CE !important'
    }
  },
  muralPaper: {
    width: '22%',
    minWidth: '22%',
    maxWidth: '22%',
    overflow: 'scroll',
    position: 'relative',
    overflowX: 'hidden',
    display: 'flex',
    borderLeft: '1px solid rgba(112, 112, 112, 0.2)'
  },
  loadingPageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center'
  },
  customTooltip: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    maxWidth: 'fit-content !important',
    backgroundColor: '#676D82 !important'
  },
  mainAreaContainer: {
    width: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B4BDD3 !important'
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '0 0 32px 0'
    },
    '@media (max-width: 800px)': {
      width: '100%',
      paddingBottom: 0
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#A8B1CE !important'
    }
  },
  mainArea: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowX: 'hidden',
    flex: 1,
    flexGrow: 1,
    maxWidth: '1440px'
  },
  headerLectureDataContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: '16px'
  },
  nameLectureBox: {
    display: 'flex',
    flex: '1',
    overflow: 'hidden'
  },
  nameLecture: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '40px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main
  },
  headerLectureButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between'
  },
  lectureDataContainer: {
    marginTop: '24px',
    marginBottom: '16px',
    padding: '0px 16px',
    flexGrow: 1,
    '@media (max-width: 1024px)': {
      padding: '20px 30px 0px 30px'
    },
    '@media (max-width: 800px)': {
      padding: '20px 10px 0px 10px'
    }
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 16px 0px 16px'
  }
}));
