import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function GenericFeedbackLayout({
  ImageIcon,
  title,
  description
}) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ gap: '16px' }}
      height="100%"
      width="100%"
    >
      <ImageIcon style={{ maxWidth: '100%' }} />
      <Typography
        style={{
          fontSize: '24px',
          fontWeight: '500',
          lineHeight: '24px',
          color: theme.palette.activeText.main,
          textWrap: 'nowrap'
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          color: '#a8b1ce',
          textWrap: 'nowrap'
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}

GenericFeedbackLayout.propTypes = {
  ImageIcon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
