import React from 'react';

import { CustomAvatar, ShimmerCircle } from './styles';
import PropTypes from 'prop-types';

export default function AvatarProfile({ size, profile, ...otherProps }) {
  return (
    <>
      {profile ? (
        <CustomAvatar profile={profile} size={size} {...otherProps} />
      ) : (
        <ShimmerCircle size={size} />
      )}
    </>
  );
}

AvatarProfile.propTypes = {
  size: PropTypes.number,
  profile: PropTypes.shape()
};

AvatarProfile.defaultProps = {
  size: 90,
  profile: null
};
