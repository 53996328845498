import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import UnlinkDialog from '~/components-refactor/molecules/UnlinkDialog';
import { EnvironmentIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import FormFilter from '~/components/FormFilter';
import AlertsDialog from '~/components/molecules/AlertsDialog';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import OrderByPopover from '~/components/OrderByPopover';
import ShareContent from '~/components/organisms/ShareContents/ShareContentEnvironments';
import AppbarPages from '~/components/templates/AppbarPages';
import Members from '~/components/templates/Members';
import i18n from '~/I18n';
import api from '~/services/api';
import { getEnrollments, searchMembers } from '~/services/environment';
import { unlink } from '~/services/mirroring/environment';
import { update as updateRole } from '~/services/user-environment-association';
import settings from '~settings';

import Classification from '../Courses/Classification';
import PageUnauthorized from '../PageUnauthorized';
import Content from './Content';
import ImportData from './ImportData';
import PaperEnvironmentInformation from './PaperEnvironmentInformation';
import Sidebar from './Sidebar';
import { useStyles } from './styles';
import { Box, Modal, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ShareIcon from '@material-ui/icons/Share';

export default function Environment() {
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: ''
    }
  });
  const theme = useTheme();
  const history = useHistory();
  const [contentIsSelected, setContentIsSelected] = useState(true);
  const [
    contentClassificationIsSelected,
    setContentClassificationIsSelected
  ] = useState(true);
  const [showEnvironmentInformation, setShowEnvironmentInformation] = useState(
    false
  );
  const classes = useStyles({ theme });

  const [importData, setImportData] = useState(false);
  const [environmentDetails, setEnvironmentDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const error = false;

  const { id } = useParams();

  const getCourses = useCallback(async () => {
    await api
      .get(`api/environments/${id}/courses`)
      .then((res) => setCourses(res.data))
      .catch(console.log);
  }, []);

  const getEnvironmentDetails = useCallback(async () => {
    try {
      const response = await api.get(`api/environments/${id}`);
      setEnvironmentDetails(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        history.push('/page-not-found');
      }
    }
  }, []);

  useEffect(() => {
    getEnvironmentDetails();
    getCourses();
  }, [id, getEnvironmentDetails, getCourses]);

  useEffect(() => {
    const getCourses = async () => {
      await api
        .get(
          `api/environments/${id}/courses?order=${
            filters.getValues().ord
          }&sort=${filters.getValues().direction}&name_like=${
            filters.getValues().name_like
          }`
        )
        .then((res) => setCourses(res.data))
        .catch(console.log);
    };

    getCourses();
  }, [
    id,
    filters.getValues().ord,
    filters.getValues().direction,
    filters.getValues().name_like
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const linkList = [
    {
      Icon: AddIcon,
      component: (
        <StructureCreationPopUp
          text={`Novo ${i18n.t(`StructuresName.Course.SingularUpper`)}`}
          structure={{
            name: 'course',
            parentStructure: {
              name: 'Environment',
              id: id
            }
          }}
          structureName={i18n.t('StructuresName.Course.SingularUpper')}
          redirectLink={`/dashboard/environments/${id}/courses/new`}
        />
      ),
      style: {
        minWidth: 150,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        justifyContent: 'flex-start'
      }
    },
    {
      link: `/dashboard/environments/${id}/edit`,
      Icon: EditIcon,
      text: i18n.t('EditEnvironment'),
      disabled: false
    },
    environmentDetails?.can_unlink && {
      Icon: LinkOffIcon,
      text: 'Desvincular espelho',
      action: (e) => {
        e.preventDefault();
        setShowUnlinkModal(true);
      },
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}`,
      disabled: false
    }
  ].filter(Boolean);

  const image =
    environmentDetails?.thumbnails &&
    environmentDetails?.thumbnails.length > 2 &&
    environmentDetails.thumbnails[2].href
      ? environmentDetails.thumbnails[2].href
      : null;

  useEffect(() => {
    if (environmentDetails) {
      document.title = `${theme.title} | ${environmentDetails?.name}`;
    } else {
      document.title = `${theme.title}`;
    }
  }, [environmentDetails]);

  return (
    <>
      {error ? (
        <PageUnauthorized />
      ) : (
        <AppbarPages theme={theme}>
          <AlertsDialog />
          <Box className={classes.content}>
            {environmentDetails && (
              <>
                <Box className={classes.sidebar}>
                  <Box
                    style={{
                      marginTop: 32
                    }}
                  >
                    {image ? (
                      <img src={`${settings.baseUrl}${image}`} />
                    ) : (
                      <EnvironmentIcon
                        width={'8rem'}
                        height={'8rem'}
                        color="white"
                      />
                    )}
                  </Box>
                  <Sidebar
                    contentIsSelected={contentIsSelected}
                    setContentIsSelected={setContentIsSelected}
                    contentClassificationIsSelected={
                      contentClassificationIsSelected
                    }
                    setContentClassificationIsSelected={
                      setContentClassificationIsSelected
                    }
                    importData={importData}
                    setImportData={setImportData}
                  />
                </Box>

                <Box className={classes.infos}>
                  <Box className={classes.infosHeader}>
                    <DashboardBreadcrumbs
                      links={[
                        {
                          name: i18n.t('MyEnvironments'),
                          path: '/dashboard/environments'
                        },
                        {
                          name: `${environmentDetails.name}`,
                          path: '#',
                          last: true
                        }
                      ]}
                    />
                    <Text
                      color="primary"
                      variant="xl"
                      style={{ letterSpacing: 1, fontWeight: 500 }}
                    >
                      {environmentDetails.name}
                    </Text>
                    <Box className={classes.ambientInformationText}>
                      <InfoIcon
                        style={{ fontSize: 20 }}
                        className={classes.infoIcon}
                      />
                      <Text
                        color="accent"
                        variant="largeTextHover"
                        onClick={() => setShowEnvironmentInformation(true)}
                      >
                        {i18n.t('InfoEnvironments')}
                      </Text>
                    </Box>
                    {showEnvironmentInformation && (
                      <PaperEnvironmentInformation
                        setShowEnvironmentInformation={
                          setShowEnvironmentInformation
                        }
                        environment={environmentDetails}
                      />
                    )}
                  </Box>
                  {environmentDetails?.can_manage && (
                    <PopUpMenu
                      text={i18n.t('ManageEnvironment')}
                      showText
                      linkList={linkList}
                    />
                  )}
                  <Box className={classes.infosContent}>
                    <Box className={classes.tabBar}>
                      <Sidebar
                        contentIsSelected={contentIsSelected}
                        setContentIsSelected={setContentIsSelected}
                        contentClassificationIsSelected={
                          contentClassificationIsSelected
                        }
                        setContentClassificationIsSelected={
                          setContentClassificationIsSelected
                        }
                        importData={importData}
                        setImportData={setImportData}
                      />
                    </Box>

                    {contentIsSelected && !importData && (
                      <>
                        {!error && (
                          <FormProvider {...filters}>
                            <Text variant="mdTitle" color="primary">
                              {`${i18n.t('StructuresName.Course.PluralUpper')}`}
                            </Text>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                gap: 15
                              }}
                            >
                              <FormFilter
                                placeholder={`Pesquisar ${i18n.t(
                                  'StructuresName.Course.SingularUpper'
                                )}...`}
                              />
                              <OrderByPopover />
                            </div>

                            <Content
                              courses={courses}
                              environmentID={id}
                              environmentName={environmentDetails.name}
                            />
                          </FormProvider>
                        )}
                      </>
                    )}

                    {!contentIsSelected &&
                      !contentClassificationIsSelected &&
                      !importData && (
                        <Members
                          structureID={id}
                          getEnrollments={getEnrollments}
                          updateRole={updateRole}
                          searchMembers={searchMembers}
                          canManage={environmentDetails?.can_manage}
                        />
                      )}

                    {contentClassificationIsSelected && !contentIsSelected && (
                      <Classification isEnvironment environmentId={id} />
                    )}

                    {importData && <ImportData />}
                  </Box>
                </Box>
              </>
            )}
            <Modal
              open={open && id}
              onClose={handleClose}
              className={classes.modal}
            >
              <div>
                <ShareContent environmentID={id} />
              </div>
            </Modal>
          </Box>
        </AppbarPages>
      )}

      <UnlinkDialog
        open={showUnlinkModal}
        handleClose={() => setShowUnlinkModal(false)}
        unlink={unlink}
        structureID={environmentDetails?.id}
        callback={() => window.location.reload()}
      />
    </>
  );
}
