import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import { downloadReport } from './fetch';
import { Button, useTheme, CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';

export default function ExerciseAverageReport({ space }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const processDownloadReport = () => {
    setLoading(true);
    downloadReport(space.id)
      .then((file) => {
        setLoading(false);
        fileDownload(
          file,
          `relatorio_de_media_de_acertos_dos_exercícios_${space.name}.xls`
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Button
      disabled={loading}
      style={{
        border: `1px solid ${theme.palette.primary.main}`,
        width: 160,
        height: 60
      }}
      onClick={processDownloadReport}
    >
      {loading ? (
        <CircularProgress size={24} color="primary" />
      ) : (
        <>
          <Text
            style={{ textTransform: 'none' }}
            color="primary"
            variant="subtitle"
          >
            {' '}
            Baixar{' '}
          </Text>
          <IconWrapper color="primary" fontSize={30} Icon={GetAppIcon} />
        </>
      )}
    </Button>
  );
}
