import React from 'react';

import { ReactComponent as AudioIcon } from '~/assets/icons/lecture/audio.svg';
import { ReactComponent as ExerciseIcon } from '~/assets/icons/lecture/exercise.svg';
import { ReactComponent as FormIcon } from '~/assets/icons/lecture/form.svg';
import { ReactComponent as ForumIcon } from '~/assets/icons/lecture/forum.svg';
import { ReactComponent as ImageIcon } from '~/assets/icons/lecture/image.svg';
import { ReactComponent as MultimediaIcon } from '~/assets/icons/lecture/multimedia.svg';
import { ReactComponent as PresentationIcon } from '~/assets/icons/lecture/presentation.svg';
import { ReactComponent as VideoIcon } from '~/assets/icons/lecture/video.svg';
import { ReactComponent as VideoCallIcon } from '~/assets/icons/lecture/videocall.svg';
import { ReactComponent as YoutubeIcon } from '~/assets/icons/lecture/youtube.svg';

import PropTypes from 'prop-types';

export function LectureCardIcon({ lecture }) {
  let Icon = null;
  let color = lecture.can_read ? 'white' : '#676D82';

  switch (lecture.type) {
    case 'Seminar':
      if (lecture.lectureable.resource_type === 'upload') {
        Icon = VideoIcon;
      } else if (
        lecture.lectureable.resource_type === 'call' ||
        lecture.lectureable.resource_type === 'google_meet'
      ) {
        Icon = VideoCallIcon;
      } else if (lecture.lectureable.resource_type === 'youtube') {
        Icon = YoutubeIcon;
      } else if (lecture.lectureable.resource_type === 'audio') {
        Icon = AudioIcon;
      }
      break;
    case 'Document':
      Icon = PresentationIcon;
      break;
    case 'Exercise':
      Icon = ExerciseIcon;
      break;
    case 'Page':
      if (lecture.lectureable.forum) {
        Icon = ForumIcon;
      } else if (lecture.lectureable.image_type) {
        Icon = ImageIcon;
      } else {
        Icon = MultimediaIcon;
      }
      break;
    case 'Form':
      Icon = FormIcon;
      break;
    default:
      return <></>;
  }

  if (Icon) {
    return (
      <Icon
        style={{
          width: '72px',
          height: '72px',
          fill: color
        }}
      />
    );
  } else return <></>;
}

LectureCardIcon.propTypes = {
  lecture: PropTypes.shape({
    type: PropTypes.oneOf(['Seminar', 'Document', 'Exercise', 'Page', 'Form'])
      .isRequired,
    can_read: PropTypes.bool.isRequired,
    lectureable: PropTypes.shape({
      resource_type: PropTypes.oneOf([
        'upload',
        'call',
        'google_meet',
        'youtube',
        'audio'
      ]),
      forum: PropTypes.bool,
      image: PropTypes.bool
    }).isRequired
  }).isRequired
};
