import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Badges from '~/components/Badges';
import Environments from '~/components/Environments';
import FriendshipSuggestion from '~/components/molecules/FriendshipSuggestion';
import SideNavItem from '~/components/organisms/SideNavItem';
import SideNav from '~/components/templates/SideNav';
import i18n from '~/I18n';
import api from '~/services/api';

import { Container } from './styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const friendshipSuggestionTipMessage = `Expanda sua rede de contatos adicionando pessoas com interesses semelhantes, amigos em comum e ${i18n.t(
  `StructuresName.Environment.PluralLower`
)} compartilhados com você.`;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  WraperContainer: {
    paddingTop: 30,
    background: (props) => props.theme.palette.primary.main
  }
}));

export default function RightDrawer() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [suggestions, setSuggestions] = useState(Array(4).fill(null));
  const { profile } = useSelector((state) => state.user);

  function fetchSuggestions() {
    if (profile) {
      api
        .get(`api/users/${profile.id}/friendship_suggestion`)
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {});
    }
  }

  useEffect(() => {
    fetchSuggestions();
  }, []);

  return (
    <Container className={classes.WraperContainer}>
      <SideNav color={theme.palette.primary.main}>
        <div className="right-drawer-tour">
          <Environments />
          <Badges />
          <SideNavItem
            lastChild
            title={i18n.t('Friendship.title')}
            marginBottom={30}
            tipMessage={friendshipSuggestionTipMessage}
          >
            {suggestions.slice(0, 4).map((item, index) => (
              <FriendshipSuggestion
                key={index}
                item={item}
                marginTop={index > 0}
              />
            ))}
          </SideNavItem>
        </div>
      </SideNav>
    </Container>
  );
}
