import React, { useState } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';

import { useStyles } from './styles';
import { CircularProgress, useTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

export default function DownloadReportButton({ getReport, baseName, id }) {
  const theme = useTheme();
  const classes = useStyles();
  const [fetchingReport, setFetchingReport] = useState(false);

  function downloadReport() {
    setFetchingReport(true);
    getReport(id)
      .then((file) => fileDownload(file, `${baseName}${id}.xls`))
      .catch(console.log)
      .finally(() => setFetchingReport(false));
  }

  return (
    <ButtonSquare
      width={200}
      height={50}
      backgroundColor={theme.palette.primary.main}
      onClick={downloadReport}
      disabled={fetchingReport}
    >
      <div className={classes.buttonContent}>
        {fetchingReport ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <>
            <Typography variant="h6" className={classes.buttonText}>
              Baixar
            </Typography>
            <GetAppIcon className={classes.buttonIcon} />
          </>
        )}
      </div>
    </ButtonSquare>
  );
}

DownloadReportButton.propTypes = {
  getReport: PropTypes.func,
  baseName: PropTypes.string,
  id: PropTypes.string
};

DownloadReportButton.defaultProps = {
  getReport: () => {
    throw new Error('getReport is required!');
  },
  baseName: '',
  id: ''
};
