import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import MirroredFlag from '~/components/molecules/MirroredFlag';
import Text from '~/components/atoms/Text';
import ShareContent from '~/components/organisms/ShareContents/ShareContentEnvironments';
import i18n from '~/I18n';
import settings from '~settings';

import PopUpMenu from '../PopUpMenu';
import { useStyles } from './styles';
import { Box, Modal, Typography, useTheme } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PropTypes from 'prop-types';

export default function EnvironmentItem({ environment }) {
  const classes = useStyles();
  const theme = useTheme();
  const { thumbnails, name, description, id } = environment || {};

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const image =
    thumbnails && thumbnails.length > 2 && thumbnails[2].href
      ? thumbnails[2].href
      : null;

  let linkList = [
    {
      action: () => setOpen(true),
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}`,
      disabled: false
    }
  ];

  return (
    <div>
      <Link to={`/dashboard/environments/${id}`} className={classes.content}>
        <img
          src={image && `${settings.baseUrl}${image}`}
          alt={name}
          className={classes.imageContainer}
        />
        <Box
          display={'flex'}
          width={'100%'}
          justifyContent={'space-between'}
          overflow={'hidden'}
        >
          <Box display={'flex'} flexDirection={'column'} maxWidth={'85%'}>
            <Typography className={classes.title}>{name}</Typography>
            <div
              style={{
                marginBottom: '8px'
              }}
            >
              {environment.is_mirror && environment.can_manage && (
                <MirroredFlag type={'environment'} />
              )}
            </div>
            <Typography className={classes.description}>
              {description || i18n.t('noDescription')}
            </Typography>
          </Box>
          <Box
            className={classes.IconWraper}
            onClick={(e) => e.preventDefault()}
          >
            {environment.can_manage && (
              <PopUpMenu showText={false} linkList={linkList} />
            )}
          </Box>
        </Box>
      </Link>
      <Modal open={open && id} onClose={handleClose} className={classes.modal}>
        <div>
          <ShareContent environmentID={id} />
        </div>
      </Modal>
    </div>
  );
}

EnvironmentItem.propTypes = {
  environment: PropTypes.shape()
};

EnvironmentItem.propTypes = {
  environment: {}
};
