import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textTransform: 'none',
    minWidth: 'max-content'
  },
  customLabel: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
      fontSize: '14px',
      color: '#676D82'
    }
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0
  },
  group: {
    width: '100%'
  },
  listSectionTitle: {
    padding: '10px 15px',
    fontWeight: 'bold',
    color: '#A8B1CE',
    '& span': {
      fontSize: '12px'
    }
  },
  separator: {
    height: '1px',
    background: '#A8B1CE',
    width: '100%'
  },
  controlLabelContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  selectedBackground: {
    backgroundColor: '#eaeaea'
  }
}));

export default useStyles;
