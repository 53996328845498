import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    margin: '30px 8px',
    gap: '16px',
    borderRadius: 6,
    padding: 15,
    '&:hover': {
      background: '#E1E1E5'
    },
    alignItems: 'flex-start',
    '@media (max-width: 1023px)': {
      margin: '12px 0',
      paddingRight: 5
    }
  },

  imageContainer: {
    width: 208,
    height: 208,
    boxShadow: '5px 5px 10px rgba(0,0,0,0.2)',
    '@media (max-width: 1023px)': {
      width: 100,
      height: 100
    },
    '& img': {
      display: 'block',
      width: 208,
      height: 208,
      borderRadius: '0.5rem',
      '@media (max-width: 1023px)': {
        width: 100,
        height: 100
      }
    }
  },
  textContainer: {
    marginLeft: 16,
    marginRight: 10,
    flex: 1
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginBottom: 8,
    fontWeight: 500,
    letterSpacing: 0.1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  description: {
    fontSize: 12,
    color: theme.palette.textActiveElement.main,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  IconWraper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
