import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import { downloadReport } from './fetch';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';

export default function SimplifiedSubjectsReport({ space }) {
  const theme = useTheme();

  const [fetchingReport, setFetchingReport] = useState(false);

  const processDownloadReport = () => {
    setFetchingReport(true);
    downloadReport(space.id)
      .then((file) =>
        fileDownload(
          file,
          `${space.name}_relatorio_simplificado_de_conclusao_de_modulos.xls`
        )
      )
      .catch(console.log)
      .finally(() => setFetchingReport(false));
  };

  return (
    <Button
      style={{
        border: `1px solid ${theme.palette.primary.main}`,
        width: 160,
        height: 60
      }}
      onClick={processDownloadReport}
      disabled={fetchingReport}
    >
      {fetchingReport ? (
        <CircularProgress size={24} color="primary" />
      ) : (
        <>
          <Text
            style={{ textTransform: 'none' }}
            color="primary"
            variant="subtitle"
          >
            Baixar
          </Text>
          <IconWrapper color="primary" fontSize={30} Icon={GetAppIcon} />
        </>
      )}
    </Button>
  );
}
