import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';

import BlockOptions from './BlockOptions';
import { CustomSwitch } from './CustomSwitch';
import { DescriptionText } from './DescriptionText';
import { LabelText } from './LabelText';
import { useStyles } from './styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export default function AdvancedOptions() {
  const classes = useStyles();
  const { control, watch } = useFormContext();
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      className={classes.accordion}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          <ExpandLessIcon
            className={classes.accordionSummaryIcon}
            style={{
              marginBottom: expanded ? '8px' : 0,
              transform: expanded ? 'none' : 'rotate(90deg)'
            }}
          />
        }
      >
        <Typography className={classes.accordionSummaryText}>
          Opções Avançadas
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <BlockOptions />
        {watch('lectureable.type') != 'exercise' && (
          <Box className={classes.fieldBox}>
            <Field
              label={
                <LabelText>{`Finalizar ${i18n.t(
                  'StructuresName.Lectures.SingularLower'
                )} automaticamente?`}</LabelText>
              }
              labelPlacement={'start'}
              control={control}
              name="markable"
              checked={watch('markable')}
              size="small"
              Component={CustomSwitch}
            />

            <DescriptionText>
              Se essa opção estiver ativa, a mídia será concluída
              automaticamente após ser acessada.
            </DescriptionText>
          </Box>
        )}

        <Divider />
        <Box className={classes.fieldBox}>
          <Field
            label={<LabelText>Mural de comentário</LabelText>}
            labelPlacement={'start'}
            control={control}
            name="mural_enabled"
            checked={watch('mural_enabled')}
            size="small"
            Component={CustomSwitch}
          />
          <DescriptionText>
            Se essa opção for desativada, a mídia não terá um mural de
            comentários.
          </DescriptionText>
        </Box>
        <Box className={classes.fieldBox}>
          <Field
            label={<LabelText>Comentários privados</LabelText>}
            labelPlacement={'start'}
            control={control}
            checked={watch('private_mural')}
            name="private_mural"
            size="small"
            Component={CustomSwitch}
          />
          <DescriptionText>
            Apenas administradores e o autor poderão visualizar o comentário.
          </DescriptionText>
        </Box>
        <Divider />
        <Box className={classes.fieldBox}>
          <Field
            label={<LabelText>Anexo obrigatório</LabelText>}
            labelPlacement={'start'}
            checked={watch('mandatory_status_file')}
            control={control}
            name="mandatory_status_file"
            size="small"
            Component={CustomSwitch}
          />
          <DescriptionText>
            Ao ativar essa opção somente será possível comentar se um anexo for
            adicionado.
          </DescriptionText>
        </Box>
        <Box className={classes.fieldBox}>
          <Field
            label={<LabelText>Permitir download de anexos</LabelText>}
            labelPlacement={'start'}
            checked={watch('downloadable_status')}
            control={control}
            name="downloadable_status"
            size="small"
            Component={CustomSwitch}
          />
          <DescriptionText>
            Ative essa opção para permitir o download de anexados adicionados no
            mural da mídia.
          </DescriptionText>
        </Box>
        <Divider />
        <Box className={classes.fieldBox}>
          <Field
            label={<LabelText>Enviar notificação</LabelText>}
            labelPlacement={'start'}
            control={control}
            checked={watch('notification')}
            name="notification"
            size="small"
            Component={CustomSwitch}
          />
          <DescriptionText>
            Ative essa opção para que todos com acesso a mídia recebam
            notificação.
          </DescriptionText>
        </Box>
        <Divider />
      </AccordionDetails>
    </Accordion>
  );
}
