import React from 'react';

import settings from '~settings';

import { Avatar, Link, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

const backgroundSkeleton = 'rgba(87, 154, 255, 0.43)';

export const ShimmerCircle = styled(({ color, size, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    variant="circle"
    width={size}
    height={size}
    animation="pulse"
    style={{ backgroundColor: backgroundSkeleton, minWidth: size }}
  />
))``;

export const CustomAvatar = styled(({ size, profile, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Link
      href={profile && profile.id ? `/profile/${profile.id}` : '#'}
      style={{
        textDecoration: 'none'
      }}
    >
      <Avatar
        {...otherProps}
        style={{
          width: size,
          height: size,
          backgroundColor: '#F2F2F7',
          color: '#A8B1CE'
        }}
        src={
          profile && profile.thumbnails && profile.thumbnails.length > 5
            ? `${settings.baseUrl}${profile.thumbnails[5].href}`
            : ''
        }
      />
    </Link>
  );
})``;
