import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import { TipButton } from '../molecules/TipButton';
import { Container, BadgesList, BadgesItem, useStyles } from './styles';
import { Box, ButtonBase } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const badgesTipMessage = `Explore, aprenda e conquiste! Ganhe emblemas ao progredir em seus ${i18n.t(
  `StructuresName.Environment.PluralLower`
)} na plataforma.`;

export default function Badges() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { profile } = user && user;
  const { badges } = user && user;
  const history = useHistory();
  const targetRoute = {
    pathname: profile ? `/profile/${profile.id}` : '#',
    state: {
      targetSection: 'conquests'
    }
  };

  return (
    <Container>
      <Box className={classes.titleBox}>
        <Text variant="sideBarTitle" color="white" fontWeight={500}>
          {i18n.t('RecentAchievements')}
        </Text>
        <TipButton tipMessage={badgesTipMessage} />
      </Box>
      <BadgesList>
        {badges ? (
          Object.values(badges).map((item, index) => {
            const { badge } = item[item.length - 1];
            const image = badge.custom_fields.badge;
            if (index < 6)
              return (
                <BadgesItem key={badge.id} title={badge.description}>
                  <div>
                    <img
                      src={`${settings.baseUrl}${image}`}
                      alt={badge.custom_fields.category}
                    />
                  </div>
                </BadgesItem>
              );
          })
        ) : (
          <Text variant="text" color="white">
            Não há conquistas
          </Text>
        )}
      </BadgesList>
      <Box display="flex" justifyContent="flex-end">
        <ButtonBase
          onClick={() => history.push(targetRoute)}
          disableRipple
          className={classes.showAllButton}
        >
          <Text variant="sideBarTitle" color="white" fontWeight={400}>
            ver mais
          </Text>
          <ArrowForwardIosRoundedIcon
            style={{
              color: 'white',
              fontSize: 14,
              marginLeft: 2,
              marginTop: '5px'
            }}
          />
        </ButtonBase>
      </Box>
    </Container>
  );
}
